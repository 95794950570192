import { jsxs as _, jsx as l } from "react/jsx-runtime";
import { useEffect as g, useRef as x, useState as S } from "react";
import { Play as N } from "@vgno/icons";
import { classnames as m, formatEnDash as E } from "@vgno/utils";
import { BreakingIndicator as I } from "@vgno/core";
import '../assets/VideoTeaser.css';const M = "_title_1ngsn_1", z = "_video_1ngsn_8", P = "_small_1ngsn_12", T = "_medium_1ngsn_17", w = "_large_1ngsn_23", F = "_opinion_1ngsn_29", L = "_text_1ngsn_33", D = "_breakingPrefix_1ngsn_45", V = "_breakingIndicator_1ngsn_51", i = {
  title: M,
  video: z,
  small: P,
  medium: T,
  large: w,
  opinion: F,
  text: L,
  breakingPrefix: D,
  breakingIndicator: V
}, j = ({
  breakingNewsPrefix: s,
  hasBreakingIndicator: n,
  headlineType: t,
  isOpinion: e = !1,
  isVideo: o = !1,
  size: r,
  title: a
}) => {
  const c = `${t}`;
  return /* @__PURE__ */ _(
    c,
    {
      className: m(
        i.title,
        e && i.opinion,
        o ? ["title-medium", i.video] : i[r]
      ),
      children: [
        n && /* @__PURE__ */ l(I, { className: i.breakingIndicator }),
        s && /* @__PURE__ */ l("span", { className: i.breakingPrefix, children: `${s} ` }),
        /* @__PURE__ */ l("span", { className: i.text, children: E(a) })
      ]
    }
  );
}, v = (s) => {
  const n = Math.floor(s / 1e3), t = Math.floor(n / 3600), e = Math.floor(n % 3600 / 60), o = n % 60, r = t > 0 ? `${t}:` : "", a = `${e.toString().padStart(t > 0 ? 2 : 1, "0")}`, c = `${o.toString().padStart(2, "0")}`;
  return `${r}${a}:${c}`;
}, nt = (s) => {
  const n = Math.floor(s / 1e3), t = Math.floor(n / 3600), e = Math.floor(n % 3600 / 60), o = n % 60, r = [];
  return t > 0 && r.push(`${t} time${t !== 1 ? "r" : ""}`), e > 0 && r.push(`${e} minutt${e !== 1 ? "er" : ""}`), o > 0 && r.push(`${o} sekund${o !== 1 ? "er" : ""}`), r.join(", ");
};
function H(s, n, t) {
  g(() => {
    if (!s && (n != null && n.current)) {
      const e = () => {
        t != null && t.current && (t == null || t.current.play());
      }, o = () => {
        t != null && t.current && (t == null || t.current.pause());
      }, r = n.current;
      return r.addEventListener("mouseenter", e), r.addEventListener("mouseleave", o), () => {
        r.removeEventListener("mouseenter", e), r.removeEventListener("mouseleave", o);
      };
    }
  }, [s]);
}
function B(s, n, t, e) {
  g(() => {
    if (s && (n != null && n.current)) {
      const o = (a) => {
        a.forEach((c) => {
          c.isIntersecting && !e && (t != null && t.current) ? t.current.play() : t != null && t.current && t.current.pause();
        });
      }, r = new IntersectionObserver(o, {
        threshold: 0.5
      });
      return r.observe(n.current), () => {
        n.current && r.unobserve(n.current);
      };
    }
  }, [s, n, t]);
}
function O(s, n) {
  g(() => {
    if (s) {
      let t;
      const e = () => {
        n(!0), clearTimeout(t), t = setTimeout(() => {
          n(!1);
        }, 150);
      };
      return window.addEventListener("scroll", e), () => {
        window.removeEventListener("scroll", e), clearTimeout(t);
      };
    }
  }, [s]);
}
const Y = "_article_z9lsf_1", q = "_content_z9lsf_6", A = "_video_z9lsf_18", C = "_link_z9lsf_22", G = "_durationPill_z9lsf_32", J = "_duration_z9lsf_32", K = "_titleFlex_z9lsf_51", u = {
  article: Y,
  content: q,
  video: A,
  link: C,
  durationPill: G,
  duration: J,
  titleFlex: K
}, st = ({
  className: s = "",
  contentType: n,
  duration: t,
  headlineType: e,
  isOpinion: o,
  onClick: r,
  title: a,
  url: c,
  videoAspectRatio: $,
  videoPreviewURL: b,
  ...f
}) => {
  const d = window.matchMedia("(max-width: 720px)").matches, h = x(null), p = x(null), [k, y] = S(!1);
  return H(d, p, h), O(d, y), B(d, p, h, k), Q($) ? void 0 : /* @__PURE__ */ l(
    "article",
    {
      className: m(u.article, s),
      ref: p,
      ...f,
      children: /* @__PURE__ */ _(
        "a",
        {
          className: m("label-primary", u.link),
          "data-use-overlay-player": n === "video",
          href: c.toString(),
          onClick: r ? () => r : () => {
          },
          children: [
            b && /* @__PURE__ */ _(
              "video",
              {
                autoPlay: !1,
                className: m(u.video),
                loop: !0,
                muted: !0,
                playsInline: !0,
                ref: h,
                children: [
                  /* @__PURE__ */ l("source", { src: b, type: "video/mp4" }),
                  "Your browser does not support the video tag."
                ]
              }
            ),
            /* @__PURE__ */ _(
              "div",
              {
                className: m(u.content, "label-small", "label-primary"),
                children: [
                  /* @__PURE__ */ l("div", { className: u.titleFlex, children: /* @__PURE__ */ l(
                    j,
                    {
                      headlineType: e,
                      isOpinion: o,
                      isVideo: !0,
                      size: "medium",
                      title: a
                    }
                  ) }),
                  /* @__PURE__ */ _("div", { className: u.durationPill, children: [
                    /* @__PURE__ */ l(N, {}),
                    t && t > 0 ? /* @__PURE__ */ l("small", { className: m(u.duration, "label-small"), children: v(t ?? 0) }) : void 0
                  ] })
                ]
              }
            )
          ]
        }
      )
    }
  );
};
function Q(s) {
  return s === void 0 ? !1 : s < 1;
}
export {
  j as T,
  st as V,
  nt as a,
  v as f
};
