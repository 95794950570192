import { jsx as e, jsxs as _ } from "react/jsx-runtime";
import { FriendlyTimestamp as K } from "@vgno/core";
import { Play as Q, Pullquote as U } from "@vgno/icons";
import { mergeStyles as W, classnames as t, getSrcSet as q, getHotspot as X } from "@vgno/utils";
import { V as Y, a as Z, f as C, T as aa } from "./chunks/VideoTeaser.BoBghr87.js";
import './assets/Teaser.css';function ea(n) {
  const l = Math.ceil(n);
  if (l > 60) {
    const c = Math.floor(l / 60), i = l % 60;
    return `${c} t ${i} min lesetid`;
  }
  return `${l} min lesetid`;
}
const la = "_article_9vanb_1", na = "_author_9vanb_5", ta = "_avatar_9vanb_16", sa = "_content_9vanb_25", ia = "_small_9vanb_35", ra = "_medium_9vanb_39", oa = "_large_9vanb_43", ca = "_overlay_9vanb_44", ma = "_figure_9vanb_51", ua = "_image_9vanb_55", _a = "_kicker_9vanb_66", da = "_label_9vanb_67", va = "_link_9vanb_75", pa = "_opinion_9vanb_123", ba = "_metaData_9vanb_129", ha = "_meta_9vanb_129", fa = "_name_9vanb_151", ga = "_paywall_9vanb_157", ya = "_picture_9vanb_161", Na = "_duration_9vanb_187", wa = "_play_9vanb_191", ka = "_pullquote_9vanb_206", Sa = "_timestamp_9vanb_215", xa = {
  article: la,
  author: na,
  avatar: ta,
  content: sa,
  small: ia,
  medium: ra,
  large: oa,
  overlay: ca,
  figure: ma,
  image: ua,
  kicker: _a,
  label: da,
  link: va,
  opinion: pa,
  metaData: ba,
  meta: ha,
  name: fa,
  paywall: ga,
  picture: ya,
  duration: Na,
  play: wa,
  pullquote: ka,
  timestamp: Sa
}, Da = {
  large: "(min-width: 580px) 580px, 100vw",
  medium: "(min-width: 580px) 580px, 100vw",
  small: "300px"
}, za = {
  column: "Meninger",
  editorial: "Leder",
  "op-ed": "Meninger"
}, Aa = {
  dinepenger: "Dine Penger",
  vg: "VG+"
}, Ma = ({ author: n, styles: l }) => {
  var m;
  const c = n.title, { value: i } = ((m = n.contacts) == null ? void 0 : m.find(
    ({ type: r }) => r === "title"
  )) || {
    value: "Journalist"
  };
  return /* @__PURE__ */ _("div", { className: t("label-small", "label-primary", l.author), children: [
    n.imageAsset && /* @__PURE__ */ e(
      "img",
      {
        alt: `Bilde av ${c}`,
        className: l.avatar,
        loading: "lazy",
        sizes: "48px",
        src: n.imageAsset.urls[0].url,
        srcSet: q(n.imageAsset)
      }
    ),
    /* @__PURE__ */ e("p", { className: l.name, children: c }),
    i && /* @__PURE__ */ e("p", { children: i })
  ] });
}, Oa = ({
  alt: n,
  authors: l,
  breakingNewsPrefix: c,
  className: i = "",
  contentType: m = "article",
  duration: r,
  fetchpriority: P,
  hasBreakingIndicator: $,
  headlineType: f = "h3",
  imageAsset: s,
  kicker: g,
  loading: j,
  newsroom: H = "vg",
  onClick: p,
  overlay: V = !1,
  paywall: O = !1,
  queryParams: B = {},
  readTimeMinutes: b,
  showTimestamp: E = !0,
  size: F = "medium",
  styles: G = {},
  timestamp: d,
  title: y,
  type: v = "news",
  url: J,
  videoAspectRatio: L = 0,
  videoPreviewURL: N = void 0,
  // if exists then we need to show a video teaser
  ...w
}) => {
  var D, z, A, M;
  const a = W(xa, G), R = Object.entries(B), h = new URL(J), k = l ? l[0] : void 0;
  for (const [T, I] of R)
    h.searchParams.set(T, I);
  const o = ["column", "editorial", "op-ed"].includes(v), u = v === "feature" ? "large" : F, S = u === "large" || o && u === "medium" || V, x = d !== void 0 && qa(d);
  return N ? /* @__PURE__ */ e(
    Y,
    {
      className: i,
      contentType: m,
      duration: r,
      headlineType: f,
      isOpinion: o,
      onClick: p,
      title: y,
      url: h,
      videoAspectRatio: L,
      videoPreviewURL: N,
      ...w
    }
  ) : /* @__PURE__ */ e(
    "article",
    {
      ...S && {
        "data-color-scheme": "dark"
      },
      className: t(
        a.article,
        a[u],
        o && a.opinion,
        (o || S) && a.overlay,
        i
      ),
      ...w,
      children: /* @__PURE__ */ _(
        "a",
        {
          className: t("label-primary", a.link),
          "data-use-overlay-player": m === "video",
          href: h.toString(),
          onClick: p ? () => p : void 0,
          children: [
            ((z = (D = s == null ? void 0 : s.urls) == null ? void 0 : D[0]) == null ? void 0 : z.url) && /* @__PURE__ */ _("picture", { className: a.picture, children: [
              /* @__PURE__ */ e(
                "img",
                {
                  alt: n || "",
                  className: a.image,
                  fetchpriority: P,
                  height: (A = s.size) == null ? void 0 : A.height,
                  loading: j,
                  sizes: Da[u],
                  src: s.urls[0].url,
                  srcSet: q(s),
                  style: {
                    objectPosition: X(s, "initial")
                  },
                  width: (M = s.size) == null ? void 0 : M.width
                }
              ),
              m === "video" && r !== void 0 && /* @__PURE__ */ _("div", { className: a.play, children: [
                /* @__PURE__ */ e(Q, { "aria-hidden": !0 }),
                r > 0 ? /* @__PURE__ */ e(
                  "small",
                  {
                    "aria-label": `Video på ${Z(r)}`,
                    className: t(a.duration, "label-small"),
                    children: C(r)
                  }
                ) : void 0
              ] })
            ] }),
            /* @__PURE__ */ _(
              "div",
              {
                className: t(a.content, "label-small", "label-primary"),
                children: [
                  o && /* @__PURE__ */ e(
                    U,
                    {
                      "aria-label": "Meninger ikon",
                      className: a.pullquote
                    }
                  ),
                  o && (v === "column" && k ? /* @__PURE__ */ e(Ma, { author: k, styles: a }) : /* @__PURE__ */ e("p", { className: a.label, children: za[v] })),
                  g && /* @__PURE__ */ e(
                    "p",
                    {
                      className: t(
                        a.kicker,
                        `label-${u === "large" ? "primary" : "secondary"}`
                      ),
                      children: g
                    }
                  ),
                  /* @__PURE__ */ e(
                    aa,
                    {
                      breakingNewsPrefix: c,
                      hasBreakingIndicator: $,
                      headlineType: f,
                      isOpinion: o,
                      size: u,
                      title: y
                    }
                  ),
                  /* @__PURE__ */ _("ul", { className: a.metaData, children: [
                    O && /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e("span", { className: t(a.paywall, a.meta), children: Aa[H] }) }),
                    d && E && /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e(
                      K,
                      {
                        className: t(a.timestamp, a.meta),
                        options: {
                          day: "numeric",
                          hour: x ? !1 : void 0,
                          minute: x ? !1 : void 0,
                          month: "short",
                          weekday: !1
                        },
                        timestamp: d
                      }
                    ) }),
                    b !== void 0 && b > 0 && /* @__PURE__ */ e("li", { children: /* @__PURE__ */ e("span", { className: t(a.meta, "label-small"), children: ea(b) }) })
                  ] })
                ]
              }
            )
          ]
        }
      )
    }
  );
};
function qa(n) {
  return (Date.now() - new Date(n).getTime()) / 36e5 > 3;
}
export {
  Oa as Teaser,
  Da as imageSizes
};
